import React, { useState, useRef, useContext, useEffect } from "react";
import PlayerDetails from "./playerDetails";
import { OptionsContext } from "../store/params.js";
import { getLSDefaultPlaying, handlePlayloop, getReaded } from "../data/storageManagement.js";
import PlayerControls from "./playerControls";

const attrAutoPlay = (audioElement) => {
  if(audioElement.current !== undefined){
    if(getLSDefaultPlaying() === 1){
      audioElement.current.autoplay = true;
    } else {
      audioElement.current.autoplay = false;
    }
  }
}

function Player() {
  const { params, setParams, updateStorageOncePlayed, getOneSongUnplayed, playedTotal, indexOrder, setIndexOrder } = useContext(OptionsContext);

  const audioElement = useRef(null);

  let [initSongVolume, setInitSongVolume] = useState(0);

  // Détermine si on continue de jouer ou pas
  attrAutoPlay(params, audioElement);


  // Quand on passe a une nouvelle chanson, l'id est changé dans les params, et on écoute cet event.
  useEffect(() => {
    const songVolume = params.audioLists[params.indexplay].volumeSonore !== undefined ? params.audioLists[params.indexplay].volumeSonore : params.defaultVolume
    // console.log(params.audioLists[params.indexplay].musicSrc);
    // console.log("songVolume", songVolume, "params.audioLists[params.indexplay]", params.audioLists[params.indexplay]);
    audioElement.current.volume = songVolume;
    audioElement.current.autoplay = getLSDefaultPlaying();
    // console.log("ça passe ici");
    setInitSongVolume(songVolume);
    attrAutoPlay(params, audioElement);
    // console.log("volume",audioElement.current.volume, "autoloop", audioElement.current.autoplay );
  }, [params.indexplay]);

  /*
  useEffect(() => {
    audioElement.current.volume = params.defaultVolume;
  }, [params.currentVolume]);
*/

  const idSongSetter = (newId) => {
    setParams((prev) => ({
      ...prev,
      ...{
        indexplay: newId,
      },
    }));
  };

  const handleEndSong = () => {
    // if last played song was the last of the list of played song
    console.log("playedTotal === indexOrder",playedTotal,indexOrder);
    if(playedTotal -1 === indexOrder){
      if(updateStorageOncePlayed(params.indexplay)){
        const newId = getOneSongUnplayed();
        idSongSetter(newId);
      }
    // ...or was in historical
    } else {
      getNextPrevSong("next");
    }
  };

  const volumeSetter = (value) => {
    setInitSongVolume(value);
    setParams((prev) => ({
      ...prev,
      ...{
        currentVolume: value,
      },
    }));
    audioElement.current.volume = value;
  };

  const getNextPrevSong = (sense) => {
    const allSongs = getReaded();
    const nextOrPrevId = sense === "prev" ? indexOrder - 1 : indexOrder + 1;
    allSongs.map((status, i) => {
      if(status === nextOrPrevId){
        idSongSetter(i);
        setIndexOrder(nextOrPrevId);
      }
    })
  }

  const SkipSong = (forwards = true) => {
    if (forwards) { 
      if(indexOrder - 1 !== playedTotal){
        handleEndSong();
      } else {
        getNextPrevSong("next");
      }
    } else {
      if(indexOrder !== 0){
        getNextPrevSong("prev");
      }
    }
  };

  // console.log("params.audioLists", params.audioLists);


  return (
    <>
      <div className="music-player">
        <div className="player">
          <PlayerDetails song={params.audioLists[params.indexplay]} />

          <div className="wrapperAudio">
            <button className={`${playedTotal === 0 ? "inactive " : " "}skip-btn prev`} onClick={() => SkipSong(false)} />
            <audio
              src={params.audioLists[params.indexplay].musicSrc}
              ref={audioElement}
              controls={true}
              onEnded={() => { handleEndSong(); handlePlayloop("play")}}
              onPlay={() => handlePlayloop("play")}
              onPause={() => handlePlayloop("stop")}
            ></audio>
            <button className="skip-btn next" onClick={() => SkipSong()} />
          </div>

          <PlayerControls volume={initSongVolume} volumeSetter={volumeSetter} />
        </div>

      </div>
    </>
  );
}

export default Player;

import { LOCAL_STORAGE_KEY_KEEP_PLAYING, LOCAL_STORAGE_KEY_PROGRESS, getKeyFromLS, returnGoodObject } from "../store/params.js";

export const handlePlayloop = (playorstop) => {
    if(playorstop === "play"){
      localStorage.setItem(LOCAL_STORAGE_KEY_KEEP_PLAYING, 1);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY_KEEP_PLAYING, 0);
    }
}

export const getStorage = () => {
    if(localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS) !== null){
      const stringInStorage = localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS);
      return JSON.parse(stringInStorage);
    } else {
        return {"empty": []};
    }
}
  
export const getReaded = () => {
  const storageData = getStorage();
  // A modifier car on ne veux pas forcément le premier tableau...
  // console.log("storageData", storageData);
  return Object.values(storageData)[0];
}

/*
export const getPlaylistInLS = (key) => {
  const storageData = getStorage();
  return storageData[key];
}
*/

export const getLSDefaultPlaying = () => {
    let autoplay = null;
    if(localStorage.getItem(LOCAL_STORAGE_KEY_KEEP_PLAYING) === null){
      localStorage.setItem(LOCAL_STORAGE_KEY_KEEP_PLAYING, 0);
      autoplay = 0;
    }else{
      autoplay = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_KEEP_PLAYING));
    }
    return autoplay;
}

export const getIndexLastSongPlayed = () => {
  let numberplayed = 0;
  const playlist = getKeyFromLS();
  if(localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS) !== null){
    if(typeof JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS)) === "object"){
      const stringInStorage = localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS);
      const objectInStorage = JSON.parse(stringInStorage);
      const playlistValue = objectInStorage[playlist];
      numberplayed = playlistValue.filter(song => !!song).length;
    }
  }
  return numberplayed;
}

export const updateStorage = (id) => {
  const songs = returnGoodObject(id);

  if(localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS) !== null){
    const emptyArray = Array(songs.length).fill(false);
    const initialisationLocalStorage = JSON.stringify({ [id]: emptyArray });
    localStorage.setItem(LOCAL_STORAGE_KEY_PROGRESS, initialisationLocalStorage);
  }
}

export const cleanStorage = () => {
    const getName = getKeyFromLS();
    updateStorage(getName);
}
// import {preparePlaylist} from "./utils";

const playlistSimonAverage = [
  {
    name: "Daddy cool",
    singer: "Boney M",
    musicSrc: "/song/mario/Boney M. - Daddy Cool (Sopot Festival 1979) (VOD).mp3",
    cover: "/song/cover/Default.jpg",
    volumeSonore: 0.7,
  },
  {
    name: "Back In Black",
    singer: "AC",
    musicSrc: "/song/Simon/ACDC - Back In Black (Official Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ma Petite Entreprise",
    singer: "Alain Bashung",
    musicSrc: "/song/Simon/Alain Bashung - Ma Petite Entreprise.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Berlin erwacht - Bob Telden (1969)",
    singer: "_",
    musicSrc: "/song/Simon/Berlin erwacht - Bob Telden (1969).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Moon",
    singer: "Clint Mansell",
    musicSrc: "/song/Simon/Clint Mansell - Moon (theme).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Scandinavian Crush",
    singer: "Craft Spells",
    musicSrc: "/song/Simon/Craft Spells - Scandinavian Crush.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Culture Beat",
    singer: "Mr. Vain",
    musicSrc: "/song/Simon/Culture Beat-Mr. Vain.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Enter The Ninja",
    singer: "DIE ANTWOORD",
    musicSrc: "/song/Simon/Die Antwoord - Enter The Ninja.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "PITBULL TERRIER",
    singer: "DIE ANTWOORD",
    musicSrc: "/song/Simon/DIE ANTWOORD - PITBULL TERRIER.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "UGLY BOY",
    singer: "DIE ANTWOORD",
    musicSrc: "/song/Simon/DIE ANTWOORD - UGLY BOY.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Drop The Bomb",
    singer: "Disasteradio",
    musicSrc: "/song/Simon/Disasteradio - Drop The Bomb.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Rose Tattoo",
    singer: "Dropkick Murphys",
    musicSrc: "/song/Simon/Dropkick Murphys - Rose Tattoo.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Irish Rovers",
    singer: "Drunken Sailer",
    musicSrc: "/song/Simon/Drunken Sailer - Irish Rovers.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Time of the Gypsies",
    singer: "Goran Bregović, Emir Kusturica",
    musicSrc: "/song/Simon/Ederlezi Time of the Gypsies - Goran Bregović, Emir Kusturica.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Milord",
    singer: "Edith Piaf",
    musicSrc: "/song/Simon/Edith Piaf - Milord paroles (lyrics).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Not Afraid",
    singer: "Eminem",
    musicSrc: "/song/Simon/Eminem - Not Afraid.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The Real Slim Shady",
    singer: "Eminem",
    musicSrc: "/song/Simon/Eminem - The Real Slim Shady.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Without Me",
    singer: "Eminem",
    musicSrc: "/song/Simon/Eminem - Without Me .mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Comme un boomerang",
    singer: "Etienne Daho",
    musicSrc: "/song/Simon/Etienne Daho - Comme un boomerang (duo live avec Dani) - clip officiel.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 1,
  },
  {
    name: "Sweet Dreams",
    singer: "Eurythmics",
    musicSrc: "/song/Simon/Eurythmics, Annie Lennox, Dave Stewart - Sweet Dreams (Are Made Of This) (Official Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Funkytown",
    singer: "Funkytown",
    musicSrc: "/song/Simon/Funkytown.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ces Dieux-Là",
    singer: "Gabriel Yacoub",
    musicSrc: "/song/Simon/Gabriel Yacoub - Ces Dieux-Là.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "L'EAU",
    singer: "Gabriel Yacoub",
    musicSrc: "/song/Simon/Gabriel Yacoub - L'EAU.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Freed from desire",
    singer: "GALA",
    musicSrc: "/song/Simon/GALA - Freed from desire [Official Video].mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "I Think I'm Paranoid",
    singer: "Garbage",
    musicSrc: "/song/Simon/Garbage - I Think I'm Paranoid (Official Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Georges Brassens   Les copains d´abord",
    singer: "Georges Brassens",
    musicSrc: "/song/Simon/Georges Brassens   Les copains d´abord.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Chanson pour l'auvergnat",
    singer: "Georges Brassens",
    musicSrc: "/song/Simon/Georges Brassens - Chanson pour l'auvergnat.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Le Gorille",
    singer: "Georges Brassens",
    musicSrc: "/song/Simon/Georges Brassens - Le Gorille.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Heavy Cross",
    singer: "Gossip",
    musicSrc: "/song/Simon/Gossip - Heavy Cross (Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Genesis",
    singer: "Grimes",
    musicSrc: "/song/Simon/Grimes - Genesis.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "We Appreciate Power",
    singer: "Grimes",
    musicSrc: "/song/Simon/Grimes - We Appreciate Power (Lyric Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Hijo de la luna",
    singer: "_",
    musicSrc: "/song/Simon/Hijo de la luna subtitulada.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Santiano",
    singer: "Hugues aufray",
    musicSrc: "/song/Simon/Hugues aufray - Santiano.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Good For You",
    singer: "Icona Pop",
    musicSrc: "/song/Simon/Icona Pop - Good For You.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Lets Go All The Way!",
    singer: "ICP",
    musicSrc: "/song/Simon/ICP Insane Clown Posse - Lets Go All The Way!.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Indiana Jones",
    singer: "John Williams",
    musicSrc: "/song/Simon/Indiana Jones Theme Song.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Jacques Brel Dans Le Port d'Amsterdam",
    singer: "Jacques Brel",
    musicSrc: "/song/Simon/Jacques Brel   Dans Le Port d'Amsterdam.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "La valse à mille temps",
    singer: "Jacques Brel",
    musicSrc: "/song/Simon/Jacques Brel - La valse à mille temps.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ne me quitte pas",
    singer: "Jacques Brel",
    musicSrc: "/song/Simon/Jacques Brel - Ne Me Quitte Pas.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Makeba",
    singer: "Jain",
    musicSrc: "/song/Simon/Jain - Makeba.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Dieu",
    singer: "Java",
    musicSrc: "/song/Simon/Java -Dieu.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Jurassic Park",
    singer: "John Williams",
    musicSrc: "/song/Simon/Jurassic Park - Main Theme.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Babooshka",
    singer: "Kate Bush",
    musicSrc: "/song/Simon/Kate Bush - Babooshka - Official Music Video.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Stand on the word",
    singer: "Keedz",
    musicSrc: "/song/Simon/Keedz - Stand on the word (official video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Pursuit Of Happiness",
    singer: "Kid CuDi",
    musicSrc: "/song/Simon/Kid CuDi Pursuit Of Happiness.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Moon",
    singer: "Kid Francescoli",
    musicSrc: "/song/Simon/Kid Francescoli - Moon (Official Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Bette Davis Eyes",
    singer: "Kim Carnes",
    musicSrc: "/song/Simon/Kim Carnes - Bette Davis Eyes (Extended Mix).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Cambodia",
    singer: "Kim Wilde",
    musicSrc: "/song/Simon/Kim Wilde - Cambodia (1981) HD 0815007.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Sorry I Am Late",
    singer: "KOLLEKTIV TURMSTRASSE",
    musicSrc: "/song/Simon/KOLLEKTIV TURMSTRASSE - Sorry I Am Late.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "La bombe",
    singer: "Sexy Sushi",
    musicSrc: "/song/Simon/La bombe (Ms Mix) [Herr Silver Version] - Sexy Sushi.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "La Foule",
    singer: "Edith Piaf",
    musicSrc: "/song/Simon/La Foule.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The Whistleblowers",
    singer: "Laibach",
    musicSrc: "/song/Simon/Laibach - The Whistleblowers (Spectre) official video.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Carol of the Bells",
    singer: "Leontovych",
    musicSrc: "/song/Simon/Leontovych - Carol of the Bells.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Les flamandes",
    singer: "Jacques Brel",
    musicSrc: "/song/Simon/Les flamandes.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Zobi La Mouche",
    singer: "Les Négresses Vertes",
    musicSrc: "/song/Simon/Les Négresses Vertes - Zobi La Mouche.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Dañs Gwadek 2",
    singer: "Les Ramoneurs de menhirs",
    musicSrc: "/song/Simon/Les Ramoneurs de menhirs - Dañs Gwadek 2.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 0.5,
  },
  {
    name: "Menez Daou",
    singer: "Les Ramoneurs de Menhirs",
    musicSrc: "/song/Simon/Les Ramoneurs de Menhirs - Menez Daou.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 0.5,
  },
  {
    name: "Marcia Baïla",
    singer: "Les Rita Mitsouko",
    musicSrc: "/song/Simon/Les Rita Mitsouko - Marcia Baïla.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 0.9,
  },
  {
    name: "Plus-Rien",
    singer: "Les-Cowboys-Fringants",
    musicSrc: "/song/Simon/Les-Cowboys-Fringants-Plus-Rien (LYRICS IN DESCRIPTION BAR).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Narcotic",
    singer: "Liquido",
    musicSrc: "/song/Simon/Liquido - Narcotic.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Loituma",
    singer: "Ievan Polkka",
    musicSrc: "/song/Simon/Loituma - Ievan Polkka.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Hard Rock Hallelujah",
    singer: "Lordi",
    musicSrc: "/song/Simon/Lordi - Hard Rock Hallelujah (Finland) 2006 Eurovision Song Contest Winner.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "El condor pasa",
    singer: "Los Incas",
    musicSrc: "/song/Simon/Los Incas El condor pasa 1963 VERSION ORIGINALE.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "le grand coureur",
    singer: "luc arbogast",
    musicSrc: "/song/Simon/luc arbogast le grand coureur.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "I Follow Rivers",
    singer: "Lykke Li",
    musicSrc: "/song/Simon/Lykke Li - I Follow Rivers (clip officiel).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Paper Planes",
    singer: "M.I.A.",
    musicSrc: "/song/Simon/M.I.A. - Paper Planes.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "XXXO",
    singer: "M.I.A.",
    musicSrc: "/song/Simon/M.I.A. - XXXO.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Midnight City",
    singer: "_",
    musicSrc: "/song/Simon/M83 'Midnight City' Official video.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "THRIFT SHOP FEAT. WANZ",
    singer: "MACKLEMORE & RYAN LEWIS",
    musicSrc: "/song/Simon/MACKLEMORE & RYAN LEWIS - THRIFT SHOP FEAT. WANZ (OFFICIAL VIDEO).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Lean On",
    singer: "Major Lazer & DJ Snake",
    musicSrc: "/song/Simon/Major Lazer & DJ Snake - Lean On (feat. MØ) (Official Music Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Music of the Andes",
    singer: "Music of the Andes",
    musicSrc: "/song/Simon/Music of the Andes.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Hasta siempre",
    singer: "Nathalie Cardone",
    musicSrc: "/song/Simon/Nathalie Cardone - Hasta siempre.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "J'Ai Vu",
    singer: "Niagara",
    musicSrc: "/song/Simon/Niagara - J'Ai Vu.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "numa numa yei",
    singer: "O-zone",
    musicSrc: "/song/Simon/O-zone numa numa yei.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The Kids Aren't All Right",
    singer: "Off Spring",
    musicSrc: "/song/Simon/Off Spring - The Kids Aren't All Right.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "russian couplets while fighting",
    singer: "Otava Yo",
    musicSrc: "/song/Simon/Otava Yo - russian couplets while fighting.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Padam padam",
    singer: "Edith Piaf",
    musicSrc: "/song/Simon/Padam padam.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Mundian To Bach Ke",
    singer: "Panjabi MC",
    musicSrc: "/song/Simon/Panjabi MC  - Mundian To Bach Ke.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The Show Must Go On",
    singer: "Queen",
    musicSrc: "/song/Simon/Queen - The Show Must Go On.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Eifersucht",
    singer: "Rammstein",
    musicSrc: "/song/Simon/Rammstein - Eifersucht.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ich Will",
    singer: "Rammstein",
    musicSrc: "/song/Simon/Rammstein - Ich Will.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Asche zu asche",
    singer: "Rammstein",
    musicSrc: "/song/Simon/Rammstein-Asche zu asche.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Salutare",
    singer: "Rummelsnuff",
    musicSrc: "/song/Simon/Rummelsnuff - Salutare.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Wild Dances",
    singer: "Ruslana",
    musicSrc: "/song/Simon/Ruslana - Wild Dances.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Sarà perchè ti amo",
    singer: "Ricchi e poveri",
    musicSrc: "/song/Simon/Sarà perchè ti amo - Ricchi e poveri - testo.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Say Shava Shava",
    singer: "_",
    musicSrc: "/song/Simon/Say Shava Shava.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Son ar Chistr Gwennyn",
    singer: "Tri Yann",
    musicSrc: "/song/Simon/Son ar Chistr Gwennyn ,Tri Yann, Gilles Servat,.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 1,
  },
  {
    name: "Stupeflip Vite",
    singer: "Stupeflip",
    musicSrc: "/song/Simon/Stupeflip - Stupeflip Vite !!!.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Sunday Bloody Sunday",
    singer: "U2",
    musicSrc: "/song/Simon/Sunday Bloody Sunday.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Tell Me Why",
    singer: "Supermode",
    musicSrc: "/song/Simon/Supermode - Tell Me Why (Original Mix).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Greyhound",
    singer: "Swedish House Mafia",
    musicSrc: "/song/Simon/Swedish House Mafia - Greyhound - Extended Video Remix HD.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Chop Suey!",
    singer: "System Of A Down",
    musicSrc: "/song/Simon/System Of A Down - Chop Suey! (Official HD Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ihr wolltet Spaß",
    singer: "Tanzwut",
    musicSrc: "/song/Simon/Tanzwut - Ihr wolltet Spaß.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Rocky Road to Dublin",
    singer: "The Dubliners",
    musicSrc: "/song/Simon/The Dubliners - Rocky Road to Dublin.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Wolf Totem",
    singer: "The HU",
    musicSrc: "/song/Simon/The HU - Wolf Totem (Official Music Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Pass This On",
    singer: "The Knife",
    musicSrc: "/song/Simon/The Knife - 'Pass This On'  Future Shorts.mp3",
    volumeSonore: 0.9,
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Young Blood",
    singer: "The Naked And Famous",
    musicSrc: "/song/Simon/The Naked And Famous - Young Blood.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Message In A Bottle",
    singer: "The Police",
    musicSrc: "/song/Simon/The Police - Message In A Bottle.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "In the Shadows",
    singer: "The Rasmus",
    musicSrc: "/song/Simon/The Rasmus - In the Shadows.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Paint It, Black",
    singer: "The Rolling Stones",
    musicSrc: "/song/Simon/The Rolling Stones - Paint It, Black.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The Wellerman",
    singer: "Gingertail",
    musicSrc: "/song/Simon/The Wellerman (Gingertail Cover).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "DANCE MONKEY",
    singer: "TONES AND I",
    musicSrc: "/song/Simon/TONES AND I - DANCE MONKEY.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Dans La Lune Au Fond De L'Eau",
    singer: "Tri Yann",
    musicSrc: "/song/Simon/Tri Yann - Dans La Lune Au Fond De L'Eau.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "I Rim Bo Ro",
    singer: "Tri Yann",
    musicSrc: "/song/Simon/Tri Yann - I Rim Bo Ro.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 0.5,
  },
  {
    name: "Tunak Tunak Tun",
    singer: "Daler Mehndi",
    musicSrc: "/song/Simon/Tunak Tunak Tun - Daler MehndiOfficial VideoSanjeev AnandShahab AllahabadiYogesh.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Pride",
    singer: "U2",
    musicSrc: "/song/Simon/U2 - Pride (In The Name Of Love).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "When Johnny Comes Marching Home",
    singer: "_",
    musicSrc: "/song/Simon/When Johnny Comes Marching Home - A Song Of The American Civil War.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Heads Will Roll",
    singer: "Yeah Yeah Yeahs",
    musicSrc: "/song/Simon/Yeah Yeah Yeahs - Heads Will Roll.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Rammstein - Los",
    singer: "Rammstein",
    musicSrc: "/song/simon2/09. Rammstein - Los.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "little swing",
    singer: "AronChupa",
    musicSrc: "/song/simon2/AronChupa - Little Swing ft. Little Sis Nora  OFFICIAL VIDEO.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The woodchuck song",
    singer: "AronChupa",
    musicSrc: "/song/simon2/AronChupa Little Sis Nora The Woodchuck Song.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "lazy jay",
    singer: "AZEALIA BANKS",
    musicSrc: "/song/simon2/AZEALIA BANKS - 212 FT. LAZY JAY.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Bambola",
    singer: "BETTA LEMME",
    musicSrc: "/song/simon2/BETTA LEMME - BAMBOLA.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Cher",
    singer: "Believe",
    musicSrc: "/song/simon2/Cher - Believe.mp3",
    cover: "/song/cover/Simon.jpg",
    volumeSonore: 1,
  },
  {
    name: "This is America",
    singer: "Childish Gambino",
    musicSrc: "/song/simon2/Childish-Gambino-This-Is-America.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Metro",
    singer: "Java",
    musicSrc: "/song/simon2/Clip metro Java.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Slagysta",
    singer: "Die Antwoord - Baita Jou Sabela",
    musicSrc: "/song/simon2/DieAntwoord-BaitaJouSabelafeatSlagysta.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Kamaz",
    singer: "DJ Blyatman",
    musicSrc: "/song/simon2/DJ Blyatman длб - Kamaz.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "y2meta.com - Dr. Dre - Still D.R.E. (Official Music Video) ft. Snoop Dogg",
    singer: "_",
    musicSrc: "/song/simon2/Dr. Dre - Still D.R.E. ft. Snoop Dogg.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "_",
    singer: "Dubioza kolektiv No Escape",
    musicSrc: "/song/simon2/Dubioza kolektiv No Escape (from Balkan).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Då som nu för alltid",
    singer: "Kent",
    musicSrc: "/song/simon2/Då Som Nu För Alltid Long Version.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "_",
    singer: "Shum",
    musicSrc: "/song/simon2/Go_A - SHUM - Ukraine 🇺🇦 - Eurovision 2021.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Prince de la vigne",
    singer: "Svinkels",
    musicSrc: "/song/simon2/Gérard Baste ( Svinkels ) - Prince De La Vigne.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Trop gros",
    singer: "Svinkels",
    musicSrc: "/song/simon2/Gérard Baste ( Svinkels ) - Trop Gros.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "It's raining men",
    singer: "Geri helliwell",
    musicSrc: "/song/simon2/It's Raining Men.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Alright",
    singer: "Jain",
    musicSrc: "/song/simon2/Jain - Alright.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ce s'ra tout",
    singer: "Java",
    musicSrc: "/song/simon2/Java - Ce s'ra tout.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "California Gurls",
    singer: "Katy Perry",
    musicSrc: "/song/simon2/Katy Perry - California Gurls ft. Snoop Dogg.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Bad romance",
    singer: "Lady Gaga",
    musicSrc: "/song/simon2/Lady Gaga - Bad Romance.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Pocker face",
    singer: "Lady Gaga",
    musicSrc: "/song/simon2/Lady Gaga - Poker Face.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "So long",
    singer: "Laibach",
    musicSrc: "/song/simon2/Laibach - So Long, Farewell.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "_",
    singer: "Le disco",
    musicSrc: "/song/simon2/Le Disco.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Acid rain",
    singer: "Lorn",
    musicSrc: "/song/simon2/Lorn-AcidRain.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Bad girls",
    singer: "MIA",
    musicSrc: "/song/simon2/M.I.A. - Bad Girls.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Borders",
    singer: "MIA",
    musicSrc: "/song/simon2/M.I.A. - Borders.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "J'me tire",
    singer: "Maître Gims",
    musicSrc: "/song/simon2/Maître Gims - J'me tire.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Bienvenue à la Banane",
    singer: "Moha La Squale",
    musicSrc: "/song/simon2/Moha La Squale - Bienvenue à la Banane.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Soleil Soleil",
    singer: "Nana Mouskouri",
    musicSrc: "/song/simon2/Nana Mouskouri - Soleil Soleil.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "SANA SANA",
    singer: "Nathy Peluso",
    musicSrc: "/song/simon2/Nathy Peluso - SANA SANA _ A COLORS SHOW.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Just a girl",
    singer: "No Doubt",
    musicSrc: "/song/simon2/No Doubt - Just A Girl.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Ausländer",
    singer: "Rammstein",
    musicSrc: "/song/simon2/Rammstein - Ausländer (Official Video).mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Donaukinder",
    singer: "Rammstein",
    musicSrc: "/song/simon2/Rammstein - Donaukinder.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "JEAN QUI RIT JEAN QUI PLEURE",
    singer: "RIFF COHEN",
    musicSrc: "/song/simon2/RIFF COHEN  - JEAN QUI RIT JEAN QUI PLEURE.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "J'aime",
    singer: "Riff Cohen",
    musicSrc: "/song/simon2/Riff Cohen - J'aime.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Satan i gatan",
    singer: "_",
    musicSrc: "/song/simon2/Satan i gatan.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "The Antidote",
    singer: "Stupeflip",
    musicSrc: "/song/simon2/Stupeflip - The Antidote.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "C-Real Killer",
    singer: "Svinkels",
    musicSrc: "/song/simon2/Svinkels - C-Real Killer.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Dirty Centre",
    singer: "Svinkels",
    musicSrc: "/song/simon2/Svinkels - Dirty Centre.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "KREVARD",
    singer: "Svinkels",
    musicSrc: "/song/simon2/Svinkels - KREVARD.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Réveille le punk",
    singer: "Svinkels",
    musicSrc: "/song/simon2/Svinkels - Réveille le punk.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "House of the Rising Sun",
    singer: "The Animals",
    musicSrc: "/song/simon2/The Animals - House of the Rising Sun.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Time To Dance",
    singer: "The Shoes",
    musicSrc: "/song/simon2/The Shoes - Time To Dance.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "Hit sale",
    singer: "Therapie TAXI ft. Roméo Elvis",
    musicSrc: "/song/simon2/Therapie TAXI ft. Roméo Elvis - Hit Sale.mp3",
    cover: "/song/cover/Simon.jpg",
  },
  {
    name: "This Is Berk",
    singer: "_",
    musicSrc: "/song/simon2/This Is Berk - How To Train Your Dragon OST.mp3",
    cover: "/song/cover/Simon.jpg",
  },
]


export default playlistSimonAverage;

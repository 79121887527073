import { LOCAL_STORAGE_KEY_KEEP_PLAYING } from "../store/params.js";

export function preparePlaylist(playlist) {
  return playlist.map((obj) => ({
    ...obj,
    musicSrc: `http://cap.great-site.net/musique${obj.musicSrc}`,
    // musicSrc: `http://localhost:3000${obj.musicSrc.slice(1)}`
  }));
}

export function classes(obj) {
  const classes = []
  const entries = Object.entries(obj)
  for (const [name, check] of entries) {
    if (check) classes.push(name)
  }
  return classes.join(' ')
}
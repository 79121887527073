import { useContext, useState, useEffect } from "react";
import playlistMario from "../data/playlistMario";
import playlistSimonAverage from "../data/playlistSimonAverage";
import { OptionsContext } from "../store/params.js";
import { updateStorage, cleanStorage } from "../data/storageManagement.js";
import arraygex from "../data/regex";
import Player from "./player.js";
import Playlist from "./playlist";
import SpeechRecognition, {
  useSpeechRecognition,
  resetTranscript,
} from "react-speech-recognition";

import m4v from "../src/blank.m4v";
import ogv from "../src/blank.ogv";
import webm from "../src/blank.webm";

function Main() {
  const { params, setParams, resetAudioListStatus } = useContext(OptionsContext);

  const [micro, setMicro] = useState(true);
  // const [nextSongIndex,setNextSongIndex] = useState(params.indexplay + 1);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const changeListening = () => {
    setMicro((micro) => !micro);
    if (micro) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
  };

  const updateParams = (newparam) => {
    // const order = {clearPriorAudioLists: true, quietUpdate: false, }
    setParams((prev) => ({ ...prev, ...newparam }));
    // console.log("params", params);
  };

  const onChangeToFirstAudioList = () => {
    updateStorage("playlistMario");
    updateParams({
      audioLists: playlistMario,
    });
  };

  const onChangeToSecondAudioList = () => {
    updateStorage("playlistSimonAverage");
    updateParams({
      audioLists: playlistSimonAverage,
    });
  };

  const refreshData = () => {
    cleanStorage();
    resetAudioListStatus();
  }

  return (
    <div className="App">

      <div className="music-player">
        <Player />
      </div>

      <div className="bandana">
        <div
          onClick={changeListening}
          className={micro ? "ear earOn" : "ear earOff"}
        />
        <video controls loop>
          <source src={m4v} type="video/m4v" />
          <source src={ogv} type="video/ogg" />
          <source src={webm} type="video/webm" />
        </video>
      </div>

      <nav>
        <div
          className={`button${
            params.audioLists === playlistMario ? " active" : ""
          }`}
          onClick={onChangeToFirstAudioList}
        >
          Playliste Mario ({playlistMario.length})
        </div>
        <div
          className={`button${
            params.audioLists === playlistSimonAverage ? " active" : ""
          }`}
          type="button"
          onClick={onChangeToSecondAudioList}
        >
          Playliste Simon ({playlistSimonAverage.length})
        </div>

        <span onClick={refreshData} className="reinit">
        </span>
      </nav>

      <div className="playlist">
        <Playlist />
      </div>

      {!browserSupportsSpeechRecognition ? (
        <span>Browser doesn't support speech recognition.</span>
      ) : (
        <div>
          <p className="transcript">{transcript}</p>
        </div>
      )}
    </div>
  );
}

export default Main;

import React, { useState, createContext, useCallback } from "react";
import playlistMario from "../data/playlistMario";
import playlistSimonAverage from "../data/playlistSimonAverage.js";
import { getStorage, getReaded, getIndexLastSongPlayed } from "../data/storageManagement.js";

export const LOCAL_STORAGE_KEY_PROGRESS = "playlist-progress";
export const LOCAL_STORAGE_KEY_KEEP_PLAYING = "playlist-autoloop";

export const OptionsContext = createContext();

const createEmptyArray = () => {
  const emptyArray = Array(playlistMario.length - 1).fill(false);
  const initialisationLocalStorage = JSON.stringify({ ["playlistMario"]: emptyArray });
  localStorage.setItem(LOCAL_STORAGE_KEY_PROGRESS, initialisationLocalStorage);
}

export const getKeyFromLS = () => {
  let playlistDefault = playlistMario;
  // Si il n'y a pas de playlists initiées dans le localstorage.
  if(localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS) !== null){
    // Si les datas sont bien formatés correctements
    if(typeof JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS)) === "object"){
      const stringInStorage = localStorage.getItem(LOCAL_STORAGE_KEY_PROGRESS);
      const objectInStorage = JSON.parse(stringInStorage);
      playlistDefault = Object.keys(objectInStorage)[0];
    } else {
      createEmptyArray();  
    }
  } else {
    createEmptyArray();
  }
  return playlistDefault;
}


const extractIndexBaseOnFalse = (obj) => {
  return obj.reduce((res, value, index) => {
    if (value === false) {
      res.push(index);
    }
    return res;
  }, []);
}

const rand = (maximum) => {
  return Math.floor(Math.random() * maximum);
};

const getOneSongUnplayed = () => {
  // sort un tableau indexé normalement a la taille du nombre de chanson de la playliste sélectionnée moins celle qui ont été jouée
  const unreadSong = extractIndexBaseOnFalse(getReaded());
  // On pioche un numéro au hasard dans ce tableau
  const randomNumber = rand(unreadSong.length);
  const songIndex = unreadSong[randomNumber];
  // Retourne un index au hasard.
  return songIndex;
}

export const returnGoodObject = (thekey) => {
  let toreturn;
  if(thekey === "playlistMario") toreturn = playlistMario;
  if(thekey === "playlistSimonAverage") toreturn = playlistSimonAverage;
  return toreturn;  
}

export const OptionsProvider = (props) => {

  const theDefaultVolume = 0.6;

  const getName = getKeyFromLS();

  const currentlyPlayed = Object.values(getStorage())[0];

  const lastIdPlayed = getIndexLastSongPlayed();

  let currentlyPlay = returnGoodObject(getName);
  let randomUnplayed = getOneSongUnplayed();

  const options = {
    audioLists: currentlyPlay,
    audioListStatus: currentlyPlayed,
    indexplay: randomUnplayed,
    defaultVolume: theDefaultVolume,
    currentVolume: theDefaultVolume,
  };

  const [playedTotal, setPlayedTotal] = useState(lastIdPlayed);
  const [indexOrder, setIndexOrder] = useState(playedTotal);
  const [params, setParams] = useState({
    ...options
  });

  const updateStorageOncePlayed = (id) => {
    const storageRaw = getStorage();
    const storage = Object.values(storageRaw)[0];
    storage[id] = playedTotal; 
    setPlayedTotal(playedTotal+1);
    storageRaw[Object.keys(storageRaw)[0]] = storage;
    localStorage.setItem(LOCAL_STORAGE_KEY_PROGRESS, JSON.stringify(storageRaw));
    setParams((prev) => ({
      ...prev,
      ...{
        audioListStatus: storage,
      },
    }));
    setIndexOrder(indexOrder+1);

    // currentlyPlayed = Object.values(getStorage())[0];
    return true;
  }

  const resetAudioListStatus = () => {
    const resetedPlaylist = Array(currentlyPlay.length).fill(false);
    setParams((prev) => ({
      ...prev,
      ...{
        audioListStatus: resetedPlaylist,
      },
    }));
    setPlayedTotal(0);
    setIndexOrder(0);
  }

  const setdatas = {
    params,
    setParams,
    updateStorageOncePlayed,
    getOneSongUnplayed,
    playedTotal,
    resetAudioListStatus,
    indexOrder, 
    setIndexOrder,
  };

  return (
    <OptionsContext.Provider value={setdatas}>
      {props.children}
    </OptionsContext.Provider>
  );
};

// import {preparePlaylist} from "./utils";

const playlistMario = [
  {
    name: "L'Amérique",
    singer: "Joe Dassin",
    cover: "/song/cover/Default.jpg",
    musicSrc: "/song/mario/Joe Dassin - L' Amérique.mp3",
  },
  {
    name: "Les champs-elysées",
    singer: "Joe Dassin",
    musicSrc: "/song/mario/Joe Dassin - Les Champs-Elysées (Audio).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Siffler sur la colline",
    singer: "Joe Dassin",
    musicSrc: "/song/mario/Joe Dassin - Siffler sur la colline (Audio).mp3",
    cover: "/song/cover/Default.jpg",
  },

  {
    name: "Et si tu n'existais pas",
    singer: "Joe Dassin",
    musicSrc: "/song/mario/Et Si Tu N'Existais Pas - Joe Dassin Lyrics.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Attendez",
    singer: "Mike Brant",
    musicSrc: "/song/mario/Attendez Mike brant.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Qui saura",
    singer: "Mike Brant",
    musicSrc: "/song/mario/Qui saura - Mike Brant.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Laisse-moi t'aimer",
    singer: "Mike Brant",
    musicSrc: "/song/mario/Mike Brant - Laisse-moi t'aimer.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Emmenez-moi",
    singer: "Charles Aznavour",
    musicSrc:
      "/song/mario/Charles Aznavour - Emmenez-moi (Audio Officiel + Paroles).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "La bohème",
    singer: "Charles Aznavour",
    musicSrc: "/song/mario/Charles Aznavour - La bohème (Official Lyrics Video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Chanson populaire",
    singer: "Claude François",
    musicSrc: "/song/mario/Claude François   Chanson populaire.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Magnolias For Ever",
    singer: "Claude François",
    musicSrc: "/song/mario/Claude François - Magnolias For Ever (1977).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Belles, belles, belles",
    singer: "Claude François",
    musicSrc: "/song/mario/Claude François - Belles, belles, belles (1962).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Cette année là",
    singer: "Claude François",
    musicSrc: "/song/mario/Claude François - Cette année là.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Alexandri Alexandra",
    singer: "Claude François",
    musicSrc: "/song/mario/Claude François Alexandrie Alexandra.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Nougayork",
    singer: "Claude Nougaro",
    musicSrc: "/song/mario/Claude Nougaro - Nougayork.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "L'aziza",
    singer: "Daniel Balavoine",
    musicSrc: "/song/mario/Daniel Balavoine - L'Aziza.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Lipstick Polychrome",
    singer: "Daniel Balavoine",
    musicSrc: "/song/mario/Daniel Balavoine - Lipstick Polychrome.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Mon fils ma bataille",
    singer: "Daniel Balavoine",
    musicSrc: "/song/mario/Daniel Balavoine - Mon fils ma bataille.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Je ne suis pas un héros",
    singer: "Daniel Balavoine",
    musicSrc: "/song/mario/je ne suis pas un heros.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Sauver l'amour",
    singer: "Daniel Balavoine",
    musicSrc: "/song/mario/Sauver l'amour.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Tous les cris les S.O.S.",
    singer: "Daniel Balavoine",
    musicSrc: "/song/mario/Tous les cris les S.O.S.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Beau Malheur",
    singer: "Emmanuel Moire",
    musicSrc: "/song/mario/Emmanuel Moire - Beau Malheur.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Je Fais De Toi Mon Essentiel",
    singer: "Emmanuel Moire",
    musicSrc: "/song/mario/Emmanuel Moire - Je Fais De Toi Mon Essentiel.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Più Bella Cosa",
    singer: "Eros Ramazzotti",
    musicSrc: "/song/mario/Eros Ramazzotti - Più Bella Cosa.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Una storia importante",
    singer: "Eros Ramazzotti",
    musicSrc: "/song/mario/Eros Ramazzotti Una storia importante.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Ride like the wind",
    singer: "Christopher Cross",
    musicSrc: "/song/mario/Christopher Cross - Ride Like The Wind.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "L'avenir",
    singer: "Florent Pagny",
    musicSrc: "/song/mario/Florent Pagny - L'avenir.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Et un jour une femme",
    singer: "Florent Pagny",
    musicSrc: "/song/mario/Florent Pagny - Et Un Jour Une Femme.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Ma liberté de penser",
    singer: "Florent Pagny",
    musicSrc: "/song/mario/Florent Pagny - Ma Liberté De Penser.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Savoir aimer",
    singer: "Florent Pagny",
    musicSrc: "/song/mario/Florent Pagny - Savoir Aimer.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Nathalie",
    singer: "Gilbert Bécaud",
    musicSrc: "/song/mario/Gilbert Bécaud - Nathalie.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Je reviens te chercher",
    singer: "Gilbert Bécaud",
    musicSrc: "/song/mario/Je reviens te chercher.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "La montagne",
    singer: "Jean Ferrat",
    musicSrc: "/song/mario/Jean Ferrat   La montagne.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Rien que pour toi",
    singer: "François Feldman",
    musicSrc: "/song/mario/François Feldman - Rien que pour toi.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Rien que pour toi",
    singer: "Johnny Hallyday",
    musicSrc: "/song/mario/Johnny Hallyday - L'envie.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "La ballade",
    singer: "Gerard Lenormand",
    musicSrc: "/song/mario/La ballade des gens heureux.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Fresh",
    singer: "Kool & The Gang",
    musicSrc: "/song/mario/Kool & The Gang - Fresh (Official Music Video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "C'est la fête",
    singer: "Michel Fugain",
    musicSrc: "/song/mario/MICHEL FUGAIN C'est la fete.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Fais comme l'oiseau",
    singer: "Michel Fugain",
    musicSrc: "/song/mario/MICHEL FUGAIN fais comme l'oiseau.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Une belle histoire",
    singer: "Michel Fugain",
    musicSrc: "/song/mario/Michel Fugain - Une Belle Histoire Lyrics (HD).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Les Sunlights des tropiques",
    singer: "Gilbert Montagné",
    musicSrc: "/song/mario/Les sunlights des tropiques.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "On va s'aimer",
    singer: "Gilbert Montagné",
    musicSrc: "/song/mario/On va s'aimer.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Beat it",
    singer: "Michael Jackson",
    musicSrc: "/song/mario/Michael Jackson - Beat It (Official Video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Billie Jean",
    singer: "Michael Jackson",
    musicSrc: "/song/mario/Michael Jackson - Billie Jean (Official Video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Smooth Criminal",
    singer: "Michael Jackson",
    musicSrc:
      "/song/mario/Michael Jackson - Smooth Criminal (Single Version) HD.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Afrique Adieu",
    singer: "Michel Sardou",
    musicSrc: "/song/mario/Michel Sardou - Afrique Adieu (Audio Officiel).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "En chantant",
    singer: "Michel Sardou",
    musicSrc: "/song/mario/Michel Sardou - En chantant (Audio Officiel).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "La maladie d’amour",
    singer: "Michel Sardou",
    musicSrc: "/song/mario/Michel Sardou - La maladie d’amour (Audio Officiel).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Les lacs du Connemara",
    singer: "Michel Sardou",
    musicSrc:
      "/song/mario/Michel Sardou - Les lacs du Connemara (Official lyric video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Vattene Amore",
    singer: "Mietta",
    musicSrc: "/song/mario/Mietta   Vattene Amore.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Les gens qu'on aime",
    singer: "Patrick Fiori",
    musicSrc: "/song/mario/Patrick Fiori - Les gens qu'on aime.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "J'y vais",
    singer: "Patrick Fiori, Florent Pagny",
    musicSrc: "/song/mario/Patrick Fiori, Florent Pagny - J'y vais.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "You",
    singer: "Ten sharp",
    musicSrc: "/song/mario/Ten Sharp - You.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "La Grange",
    singer: "ZZ Top",
    musicSrc: "/song/mario/ZZ Top - La Grange.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "L'italiano",
    singer: "Toto Cotugno",
    musicSrc:
      "/song/mario/L'italiano (l asciatemi cantare) Toto Cotugno - lyrics.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Dancing Queen",
    singer: "ABBA",
    musicSrc: "/song/mario/Abba - Dancing Queen.mp3",
    cover: "/song/cover/Default.jpg",
  },

  {
    name: "Honesty",
    singer: "Billy Joel",
    musicSrc: "/song/mario/Billy Joel - Honesty (Official Video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Daddy cool",
    singer: "Boney M",
    musicSrc: "/song/mario/Boney M. - Daddy Cool (Sopot Festival 1979) (VOD).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Rasputin",
    singer: "Boney M",
    musicSrc: "/song/mario/Boney M. - Rasputin (Sopot Festival 1979) (VOD).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Sunny",
    singer: "Boney M",
    musicSrc: "/song/mario/Boney M. - Sunny (Official Video) [HD 1080p].mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "La rockeuse de diamant",
    singer: "Catherine Lara",
    musicSrc: "/song/mario/Catherine Lara - La Rockeuse de Diamants (HQ).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Mourir sur scène",
    singer: "Dalida",
    musicSrc: "/song/mario/Dalida - Mourir sur scène.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Elle a Les Yeux Revolver",
    singer: "Marc Lavoine",
    musicSrc: "/song/mario/Elle a Les Yeux Revolver - Marc Lavoine  Lyrics.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Le train",
    singer: "Marc Lavoine",
    musicSrc: "/song/mario/Marc Lavoine - Le train (Official Music Video).mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Tour d'ivoire",
    singer: "Phil Barney",
    musicSrc: "/song/mario/Phil Barney - Tour d'ivoire.mp3",
    cover: "/song/cover/Default.jpg",
  },
  {
    name: "Comment est ta peine",
    singer: "Benjamin Biolay",
    musicSrc: "/song/mario/Benjamin Biolay - Comment est ta peine  (Clip Officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Désormais",
    singer: "Charles Aznavour",
    musicSrc: "/song/mario/Charles Aznavour - Désormais (Audio Officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Les mots bleus",
    singer: "Christophe",
    musicSrc: "/song/mario/Christophe - Les mots bleus (Live Officiel Olympia 2002).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Le reste",
    singer: "Clara Luciani",
    musicSrc: "/song/mario/Clara Luciani - Le reste (Clip Officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Madame",
    singer: "Claude Barzotti",
    musicSrc: "/song/mario/Claude Barzotti - Madame.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Si J'étais Un Homme",
    singer: "Diane Tell",
    musicSrc: "/song/mario/Diane Tell - Si J'étais Un Homme (Paroles).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "I Maschi",
    singer: "Gianna Nannini",
    musicSrc: "/song/mario/Gianna Nannini - I Maschi (1987).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Une autre histoire",
    singer: "Gérard Blanc",
    musicSrc: "/song/mario/Gérard Blanc - Une autre histoire.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Just An Illusion",
    singer: "Imagination",
    musicSrc: "/song/mario/Imagination - Just An Illusion (Official Video).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Johnny Johnny",
    singer: "Jeanne Mas",
    musicSrc: "/song/mario/Jeanne Mas - Johnny Johnny (Clip officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "La Californie",
    singer: "JULIEN CLERC",
    musicSrc: "/song/mario/JULIEN CLERC La Californie 1969.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Tu m'oublieras",
    singer: "Larusso",
    musicSrc: "/song/mario/Larusso - Tu m'oublieras (Clip officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "La Solitudine",
    singer: "Laura Pausini",
    musicSrc: "/song/mario/Laura Pausini - La Solitudine (Official Video).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Tri Martolod",
    singer: "Nolwenn Leroy",
    musicSrc: "/song/mario/Nolwenn Leroy - Clip 'Tri Martolod'.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Nolwenn Ohwo",
    singer: "Nolwenn Leroy",
    musicSrc: "/song/mario/Nolwenn Leroy - Nolwenn Ohwo ! (720p HDTV).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Nos plus belles années",
    singer: "Grand Corps Malade & Kimberose",
    musicSrc: "/song/mario/Nos plus belles années - Grand Corps Malade & Kimberose (clip officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Il mio refugio",
    singer: "Richard Cocciante",
    musicSrc: "/song/mario/Richard Cocciante 'Il mio refugio'.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "The Sound of Silence",
    singer: "Simon And Garfunkel",
    musicSrc: "/song/mario/Simon And Garfunkel   The Sound of Silence   Version Original 1964.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Déjeuner En Paix",
    singer: "Stephan Eicher",
    musicSrc: "/song/mario/Stephan Eicher - Déjeuner En Paix.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "L’enfer",
    singer: "Stromae",
    musicSrc: "/song/mario/Stromae - L’enfer (Official Music Video).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "La fille du sud",
    singer: "Vianney",
    musicSrc: "/song/mario/Vianney - La fille du sud (Clip Officiel).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "Vivo per lei",
    singer: "_",
    musicSrc: "/song/mario/Vivo per lei.mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "I Have Nothing",
    singer: "Whitney Houston",
    musicSrc: "/song/mario/Whitney Houston - I Have Nothing (Official HD Video).mp3",
    cover: "/song/cover/Mario.jpg",
  },{
    name: "I Will Always Love You",
    singer: "Whitney Houston",
    musicSrc: "/song/mario/Whitney Houston - I Will Always Love You (Official 4K Video).mp3",
    cover: "/song/cover/Mario.jpg",
  }
];

export default playlistMario;

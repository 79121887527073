import React from 'react';
import 'react-jinke-music-player/assets/index.css';
import './App.css';
import Page from './components/main.js';
import { OptionsProvider } from './store/params.js';

// Get coords : https://getlatlong.net/
// 2008 https://www.facebook.com/groups/collectif20/permalink/1328297430863868/?comment_id=1328494804177464&reply_comment_id=1328537437506534
// 2009

function App() {
  return (
    <OptionsProvider>
      <Page />
    </OptionsProvider>
  );
}

export default App;

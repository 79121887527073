import React, { useContext } from "react";
import { OptionsContext } from "../store/params.js";
import { classes } from "../data/utils.js";

function Playlist() {
  const { params } = useContext(OptionsContext);

  console.log("params.audioLists", params.audioLists.length);

  return (
    <ul>
      {params.audioLists.map((el, i) => (
        <li
          key={i}
          className={classes({
            active: i === params.indexplay,
            played: params.audioListStatus[i] !== false,
          })}
        >
          {el.name} - {el.singer} - {i}
        </li>
      ))}
    </ul>
  );
}

export default Playlist;

import React, {useContext} from "react";
import { OptionsContext } from "../store/params.js";

function PlayerControls({volumeSetter, volume}) {
  // const { params } = useContext(OptionsContext);

  return (
    <>
      <div className="soundVolume">
        <span className={`${volume === 0.1 && "active"}`} onClick={() => volumeSetter(0.1)}></span>
        <span className={`${volume === 0.2 && "active"}`} onClick={() => volumeSetter(0.2)}></span>
        <span className={`${volume === 0.3 && "active"}`} onClick={() => volumeSetter(0.3)}></span>
        <span className={`${volume === 0.4 && "active"}`} onClick={() => volumeSetter(0.4)}></span>
        <span className={`${volume === 0.5 && "active"}`} onClick={() => volumeSetter(0.5)}></span>
        <span className={`${volume === 0.6 && "active"}`} onClick={() => volumeSetter(0.6)}></span>
        <span className={`${volume === 0.7 && "active"}`} onClick={() => volumeSetter(0.7)}></span>
        <span className={`${volume === 0.8 && "active"}`} onClick={() => volumeSetter(0.8)}></span>
        <span className={`${volume === 0.9 && "active"}`} onClick={() => volumeSetter(0.9)}></span>
        <span className={`${volume === 1 && "active"}`} onClick={() => volumeSetter(1)}></span>
      </div>
    </>
  );
}

export default PlayerControls;